.App {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 25% 0px auto 5%;
  grid-template-areas: 
    "top"
    "menu"
    "viewer"
    "bottom";
  overflow: hidden;
}

/* header */
.nav {
  grid-area: top;
  display: grid;
  grid-template-columns: 20% auto 20%;
  grid-template-areas: "logo title menu-button";
  background-color: #333a40;
  align-items: center;
  padding: 5px;
}

.bil-logo {
  grid-area: logo;
  margin-left: 10px;
  max-height: 80px;
  pointer-events: none;
  justify-self: center;
}

.bng_title {
  grid-area: title;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas: 
    "headline"
    "sublinks";
  color: white;
  justify-self: center;
}

.headline {
  grid-area: headline;
  justify-self: center;
}

.sublinks {
  grid-area: sublinks;
  grid-template-columns: auto auto auto;
  justify-self: center;
}

.link {
  text-decoration: none;
  color: white;
  justify-self: center;
}

.menuButtons {
  grid-area: menu-button;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-areas:
    'menu-btn'
    'fave-btn';
  position: fixed;
  margin-right: 10px;
  margin-top: 20px;
  top: 0;
  right: 0;
  z-index: 2;
}

.favorite {
  display: grid;
  grid-template-areas: 'link trash';
}

.removeFaveButton {
  background-color: transparent;
  outline: none;
  float: right;
}

.addFaveButton {
  grid-area: fave-btn;
  background-color: #007BFF; 
  color: white;
  font-size: 1rem;
  padding: 5px 10px;
  border: solid;
  border-radius: 3px;
  border-color: white;
  cursor: pointer;
}

/* menu */
.menuContainer {
  grid-area: menu;
  align-items: center;
  justify-items: center;
  background-color: #333a40;
  display: grid;
  grid-template-areas: 
    "headers"
    "links";
  z-index: 1;
  position: relative;
}

.toggle-button {
  grid-area: menu-btn;
  justify-self: right;
  background-color: #007BFF;
  color: white;
  font-size: 1rem;
  padding: 5px 10px;
  border: solid;
  border-radius: 3px;
  border-color: white;
  margin-bottom: 5px;
  cursor: pointer;

}

.menu {
  grid-area: headers;
  min-width: 600px;
  display: grid;
  grid-template-rows: 40px auto;
  grid-template-areas:
    'close'
    'items';
}

.menu-close-button {
  background: transparent;
  border: none;
  font-size: 2rem; /* adjust as needed */
  cursor: pointer;
  color: white;
  grid-area: close;
  justify-self: right;
}

.search {
  background-color: white;
}

.menu-items {
  border-style: solid;
  border-color: white;
  border-radius: 3px;
}

.expanded-content {
  z-index: 1;
  background-color: #007BFF;
  color: aliceblue;
}

.collapsible {
  padding: 10px;
  background-color: #007BFF;
  max-height: 300px; /* Set max-height equal to the height of the .menu div */
  overflow: auto; /* Provides a scrollbar if content overflows */
}

.collapsible-header {
  margin-bottom: 3px;
  font-weight: bold;
  background-color: #007BFF;
  color: aliceblue;
  cursor: pointer;
}

.collapsible-content {
  grid-area: links;
  background-color: #007BFF;
  color: aliceblue;
}

.pagination {
  width: 90%;
  background-color: #f6bb06;
  margin: 0 auto;
  margin-top: 10px;
  max-height: 25px;
}

.MuiPagination-ul {
  max-height: 25px;
}

.MuiPaginationItem-text {
  max-height: 25px;
}

.image-link-style {
  cursor: pointer;
  color: aliceblue;
  text-decoration: none;
}

.middle {
  background-color: #333a40;
}

/* main content holding neuroglancer viewer */
iframe {
  grid-area: viewer;
  min-height: 100%;
  min-width: 100%;
  padding-right: 100px;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}

.loading-indicator {
  background-color: #333a40;
  align-items: center;
  justify-items: center;
}

.loading-text{
  color:aliceblue;
  text-align: center;
  margin-top: 50px
}

.loading-button {
  display: none;
}

.noLink {
  color:aliceblue;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-top: 5px;
  padding-top: 55px;
}

/* footer */
.bottom {
  width: 100%;
  grid-area: bottom;
  background-color: #333a40;
  color: white;
  text-align: center;
  vertical-align: bottom;
}

.bil-logo-footer {
  grid-area: bottom;
  height: 12px;
  width: 10px;
}
